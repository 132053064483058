import { useState } from "react";
import { useWorkspace } from "@hooks/useWorkspace";
import omitBy from "lodash/omitBy";
import { observer } from "mobx-react";

import { CsvImport } from "@components/CsvImport";
import DialogSimple from "@components/Dialog/DialogSimple";
import {
  RequirementsCsvColumn,
  RequirementsCsvColumnMap,
  requirementsCsvColumnMap,
  requirementsCsvColumnNameMap,
} from "@components/Requirements/RequirementsTable/constants";
import appStore from "@store/AppStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";

import styles from "./RequirementsCsvImportDialog.module.scss";

interface IRequirementsCsvImportDialogProps {
  reqPage: IRequirementsPage;
  onClose(): void;
}

const RequirementsCsvImportDialog = (props: IRequirementsCsvImportDialogProps) => {
  const { reqPage, onClose } = props;
  const [columnMap, setColumnMap] = useState<RequirementsCsvColumnMap>(requirementsCsvColumnMap);
  const workspace = useWorkspace();

  const handleUpload = (file: File) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    appStore.orgModel.uploads.addNewFileUpload({
      workspaceId: workspace.id,
      files: dataTransfer.files,
      onUpload: attachmentId =>
        reqPage.createRequirementFromCsv(attachmentId, file, JSON.stringify(omitBy(columnMap, v => !v)), workspace.id),
    });
  };

  return (
    <DialogSimple
      className={styles.requirementsCsvImportDialog}
      bodyClassName={styles.requirementsCsvImportDialogBody}
      onClose={onClose}
      title="Import CSV"
      isOpen
    >
      <CsvImport<RequirementsCsvColumn>
        footerClassName={styles.requirementsCsvImportDialogFooter}
        columnMap={columnMap}
        columnNameMap={requirementsCsvColumnNameMap}
        onUpload={handleUpload}
        onChangeColumnMap={setColumnMap}
        onClose={onClose}
      />
    </DialogSimple>
  );
};

export default observer(RequirementsCsvImportDialog);
