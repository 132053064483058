import { KeyboardEvent, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Menu, MenuDivider, TagProps } from "@blueprintjs/core";
import { ItemListRendererProps, ItemRendererProps, MultiSelect, renderFilteredItems } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import { MenuItem } from "@components/MenuItem";
import { MenuItemCreateNewTag } from "@components/MenuItems/MenuItemCreateNewTag";
import MenuItemOption from "@components/Modeling/StatusesTable/Cells/MenuItemOption";
import { IPopoverRefType } from "@components/Popover";
import appStore from "@store/AppStore";
import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import type { IStatusOption } from "@store/StatusOptionStore";
import { getIntentClassFromColor, getRandomHexColor } from "@utilities";

import "./StatusTypeMultiSelectEditor.scss";

type StatusTypeMultiSelectEditorProps = {
  statusDefinition: IStatusDefinition;
  onComponentReady?: (popoverRef: RefObject<IPopoverRefType>) => void;
  singleSelect?: boolean;
  placeholder?: string;
  disabled?: boolean;
  matchTargetWidth?: boolean;
  statusInstance?: IStatusInstance;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined>;
};

function StatusTypeMultiSelectEditor(props: StatusTypeMultiSelectEditorProps) {
  const {
    statusDefinition,
    singleSelect,
    placeholder,
    disabled,
    matchTargetWidth = false,
    statusInstance,
    onComponentReady,
    onAddStatusInstance,
  } = props;
  const [query, setQuery] = useState("");
  const [color, setColor] = useState("");
  const popoverRef: RefObject<IPopoverRefType> = useRef(null);

  useEffect(() => {
    onComponentReady?.(popoverRef);
    setColor(getRandomHexColor(statusDefinition?.statusOptions?.map(d => d.color)));
  }, [onComponentReady, statusDefinition?.statusOptions]);

  const isSelected = useCallback(
    (statusOption: IStatusOption) => !!statusInstance?.multiSelectValues.find(item => item.id === statusOption.id),
    [statusInstance]
  );

  if (!statusDefinition) {
    return null;
  }

  const setValue = async (statusOption: IStatusOption) => {
    if (!statusInstance) {
      if (!statusOption) {
        return; // Don't create new instances for empty values.
      }
      onAddStatusInstance(statusDefinition, JSON.stringify([statusOption.id]));
    } else {
      if (isSelected(statusOption)) {
        statusInstance.removeOption(statusOption);
      } else {
        if (singleSelect) {
          statusInstance.clearOptionList();
        }
        statusInstance.addOptionToList(statusOption);
      }
    }

    if (singleSelect) {
      // NOTE: This might cause issue, unknown now, works as expected
      //  If weird behaviour is experienced with the look here first.
      (document.activeElement as HTMLElement).blur();
      popoverRef.current?.setState({ isOpen: false });
    }
  };

  const handleAddNewItem = async (query: string) => {
    setQuery("");
    const statusOption = appStore.workspaceModel?.addNewStatusOption(statusDefinition, query, color);

    if (statusOption) {
      if (!statusInstance) {
        const newStatusInstance = await onAddStatusInstance(statusDefinition, JSON.stringify([statusOption.id]));
        newStatusInstance?.addOptionToList(statusOption);
      } else {
        if (singleSelect) {
          statusInstance.clearOptionList();
        }
        statusInstance.addOptionToList(statusOption);
      }
    }

    setColor(getRandomHexColor(statusDefinition?.statusOptions?.map(d => d.color)));
    setQuery("");
  };

  const renderCreateNewItem = (query: string) => {
    return <MenuItemCreateNewTag query={query} color={color} onClick={handleAddNewItem} />;
  };

  const renderItem = (statusOption: IStatusOption, itemProps: ItemRendererProps) => {
    const { handleClick, modifiers } = itemProps;

    return isAlive(statusOption) ? (
      <MenuItemOption
        key={statusOption.id}
        displaySelected
        isSelected={isSelected(statusOption)}
        statusOption={statusOption}
        handleClick={handleClick}
        modifiers={modifiers}
      />
    ) : null;
  };

  const itemPredicate = (query: string, option: IStatusOption) => option.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;

  const tagProps = (value: ReactNode): TagProps => {
    const option = statusDefinition.statusOptions.find((option: IStatusOption) => option.label === value);

    return {
      minimal: false,
      className: classNames("status-type--tag", getIntentClassFromColor(option?.color)),
    };
  };

  const getPlaceHolder = () => {
    if (placeholder !== undefined) {
      return placeholder;
    } else if (singleSelect) {
      return "Empty";
    } else {
      return "Empty";
    }
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter" && query) {
      e.stopPropagation();
      e.preventDefault();
      handleAddNewItem(query);
      setQuery("");
      popoverRef.current?.setState({ isOpen: false });
    }
  };

  const noResults = <MenuItem disabled text="No results. Type to create an option" e2eIdentifiers="no-results" />;

  const renderItemList = (listProps: ItemListRendererProps<IStatusOption>) => {
    // omit noResults if createNewItemFromQuery and createNewItemRenderer are both supplied, and query is not empty
    const createItemView = listProps.renderCreateItem();
    const maybeNoResults = createItemView != null ? null : noResults;
    const menuContent = renderFilteredItems(listProps, maybeNoResults);

    if (menuContent == null && createItemView == null) {
      return null;
    }

    const title = menuContent === null ? "Create New Tag" : "Select an Option or Create a New One";

    return (
      <Menu role="listbox" {...listProps.menuProps} ulRef={listProps.itemsParentRef}>
        <MenuDivider className="title" title={title} />
        {menuContent}
        {createItemView}
      </Menu>
    );
  };

  return (
    <div className={classNames("status-type-multi-select-editor", singleSelect && "single-select")}>
      <MultiSelect<IStatusOption>
        disabled={disabled}
        query={query}
        onQueryChange={query => setQuery(query)}
        placeholder={getPlaceHolder()}
        popoverRef={popoverRef}
        resetOnSelect
        resetOnQuery
        className="status-type--multiselect"
        itemPredicate={itemPredicate}
        selectedItems={statusInstance?.multiSelectValues || []}
        onRemove={statusOption => statusInstance?.removeOption(statusOption)}
        popoverProps={{ minimal: true, matchTargetWidth, popoverClassName: "status-options-popover", position: "bottom-right" }}
        tagInputProps={{
          tagProps,
          onKeyDown: handleInputKeyDown,
        }}
        itemsEqual={(a, b) => a.id === b.id}
        tagRenderer={statusOption => statusOption?.label}
        items={statusDefinition.statusOptions}
        onItemSelect={setValue}
        noResults={noResults}
        itemRenderer={renderItem}
        itemListRenderer={renderItemList}
        createNewItemFromQuery={() => null as unknown as IStatusOption}
        createNewItemRenderer={renderCreateNewItem}
      />
    </div>
  );
}

export type { StatusTypeMultiSelectEditorProps };
export default observer(StatusTypeMultiSelectEditor);
