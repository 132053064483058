import { useEffect, useState } from "react";
import { Divider, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Profile } from "@rollup-api/models/profiles";
import appStore from "@store/AppStore";
import { IRollupEvent } from "@store/RollupEventsStore";
import { Text, TextVariant } from "src/ui/Text";

import Avatar from "../Avatar/Avatar";

import "./RollupEventsItem.scss";

interface RollupEventsItemProps {
  rollupEvent: IRollupEvent;
  lastItem?: boolean;
}

const RollupEventsItem = ({ rollupEvent, lastItem = false }: RollupEventsItemProps) => {
  const [userProfile, setUserProfile] = useState<Profile | null>(null);

  const getNotificationActionText = (entityType: string, action?: string): string => {
    if (!action) {
      return "";
    }
    switch (action) {
      case "mentionedInAComment":
      default:
        return `mentioned you in a ${entityType}`;
    }
  };

  // TODO: activate and test when we have implemented other event places in the BE.
  // let notificationPlace = "";
  // switch (rollupEvent.entityType) {
  //   case "comment":
  //     notificationPlace = "in a comment";
  //     break;
  //   case "status-instance":
  //     notificationPlace = "in a status instance";
  //     break;
  //   case "report":
  //     notificationPlace = "in a report";
  //     break;
  //   case "block":
  //     notificationPlace = "in a block";
  //     break;
  //   default:
  //     break;
  // }

  useEffect(() => {
    const getAndSetUserProfile = async () => {
      if (rollupEvent.createdBy) {
        const userProfile: Profile | null = await appStore.orgModel?.info.getUserProfile(rollupEvent.createdBy);
        setUserProfile(userProfile ?? null);
      }
    };
    getAndSetUserProfile();
  }, [rollupEvent]);

  return (
    <>
      <div className="rollup-event-item">
        {!rollupEvent.isRead && <Icon icon="dot" color="red" className="rollup-event-item--unread-dot" />}
        <div className="rollup-event-item--content">
          <div className="rollup-event-item--content--header">
            <Avatar source={userProfile?.avatarUrl} size={20} />
            <Text variant={TextVariant.H4}>{userProfile?.name}</Text>
          </div>
          <div className="rollup-event-item--content--action">
            <Text variant={TextVariant.Caption}>
              {getNotificationActionText(rollupEvent.entityType, rollupEvent.action)}
              {/* {notificationAction} {notificationPlace} */}
            </Text>
          </div>
          <div className="rollup-event-item--content--message">
            <Text>"Hey dear colleague, I have a question for you."</Text>
          </div>
        </div>
      </div>
      {!lastItem && <Divider className="notification-divider" />}
    </>
  );
};

export default observer(RollupEventsItem);
