export const ICON_WIDTH = 32;

export const DANGER_DROP = "danger-drop-zone";

export const CHEVRON_WIDTH = 24;

export const ROW_HEIGHT = 32;

export const HEADER_HEIGHT = 28;

export const CELL_MOCK = "--";

export const MULTIPLICITY_COLUMN_WIDTH = 48;

export const ACTIONS_COL_ID = "Actions";

export const MULTIPLICITY_COL_ID = "Multiplicity";

export const CREATE_NEW_COL_ID = "CREATE_NEW_COL_ID";

export const BLOCKS_TREE_COLUMN_ID = "BlocksTreeColumn";

export const TABLE_CLASS_NAME = "tabulated-view-table";

export const NAME_COLUMN = "ag-Grid-AutoColumn";

// 0, 1, 2 - are taken by multiplicity, actions & tree view columns
export const DEFAULT_SORT_INDEX = 3;
