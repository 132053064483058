import { MouseEvent, useCallback, useEffect, useState } from "react";
import { Checkbox, FormGroup, InputGroup, Intent, Spinner, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CustomIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { Icon } from "@components/Icon";
import { MultiplicityTag } from "@components/MultiplicityTag";
import { BlockExtendedModel } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { getBlockById } from "@utilities/Block";
import { createBpIcon } from "@utilities/Icon";
import { rollupClient } from "src/core/api";
import { IWorkspaceListItem } from "src/services/WorkspaceService";

import { getCatalogItemSelectionBlockPath, highlightMatchingText } from "./utils";

import "./BlockMode.scss";

const BlockTree = Tree.ofType<BlockExtendedModel>();

const BlockMode = () => {
  const [searchValue, setSearchValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blocks, setBlocks] = useState<Array<BlockExtendedModel>>([]);
  const [workspaceId, setWorkspaceId] = useState("");
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [collapsedBlocks, setCollapsedBlocks] = useState<string[]>([]);

  useEffect(() => {
    if (workspaceId) {
      setLoading(true);
      rollupClient.modelingModule.blocks
        .retrieveAllWithWorkspace(workspaceId)
        .then(res => setBlocks(res.data))
        .finally(() => setLoading(false));
    }
  }, [workspaceId]);

  const toggleExpand = useCallback(
    (e: MouseEvent<HTMLElement>, id: string) => {
      e.stopPropagation();

      if (collapsedBlocks.includes(id)) {
        setCollapsedBlocks(collapsedBlocks.filter(i => i !== id));
      } else {
        setCollapsedBlocks([...collapsedBlocks, id]);
      }
    },
    [collapsedBlocks]
  );

  const renderLabel = useCallback(
    (block: BlockExtendedModel) => {
      const expanded = !collapsedBlocks.includes(block.id);
      const hasChild = !!block.children?.length;

      return (
        <div className="block-mode--item-wrap">
          <Checkbox checked={selectedBlocks.includes(block.id)} />
          <div className="block-mode--item">
            {hasChild && (
              <Button
                onClick={e => toggleExpand(e, block.id)}
                small
                minimal
                icon={expanded ? "caret-down" : "caret-right"}
                e2eIdentifiers={["toggle-child-btn", "toggle-expand"]}
              />
            )}
            <Icon icon={block.iconData ?? createBpIcon("cube")} />
            {highlightMatchingText(block.label, searchValue)}
            {!!block.multiplicity && <MultiplicityTag multiplicity={block.multiplicity} />}
          </div>
        </div>
      );
    },
    [searchValue, collapsedBlocks, selectedBlocks, toggleExpand]
  );

  const convertToTreeNode = (block: BlockExtendedModel) => {
    const node: TreeNodeInfo<BlockExtendedModel> = {
      id: block.id,
      isExpanded: !collapsedBlocks.includes(block.id),
      hasCaret: false,
      isSelected: selectedBlocks?.includes(block.id),
      label: renderLabel(block),
      nodeData: block,
    };

    if (block.children) {
      node.childNodes = [];
      for (const b of block.children) {
        const childBlock = blocks.find(bl => bl.id === b.id);
        childBlock && node.childNodes.push(convertToTreeNode(childBlock));
      }
    }
    return node;
  };

  const nodes: TreeNodeInfo<BlockExtendedModel>[] = blocks.filter(b => !b.parentBlock).map(b => convertToTreeNode(b));

  const toggleSelection = (block: BlockExtendedModel, recursive = false) => {
    if (!block) {
      return;
    }

    if (selectedBlocks.includes(block.id)) {
      if (recursive) {
        // Remove this and all its children from selection
        setSelectedBlocks(selectedBlocks.filter(id => !getBlockById(id)?.pathIds.includes(block.id)));
      } else {
        setSelectedBlocks(selectedBlocks.filter(id => id !== block.id));
      }
    } else {
      if (recursive) {
        // Add this and all its children, preserving existing selection
        setSelectedBlocks(
          blocks
            .filter(b => b === block || selectedBlocks.includes(b.id) || getCatalogItemSelectionBlockPath(b, blocks).includes(block.id))
            .map(b => b.id)
        );
      } else {
        setSelectedBlocks([...selectedBlocks, block.id]);
      }
    }
  };

  const handleNodeClicked = (node: TreeNodeInfo<BlockExtendedModel>, _path: number[], ev: React.MouseEvent<HTMLElement>) => {
    if (node.nodeData) {
      toggleSelection(node.nodeData, ev.shiftKey || ev.ctrlKey || ev.metaKey);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    await appStore.orgModel.catalogItems.createCatalogItemsFromBlocks(selectedBlocks, appStore.workspaceModel?.id);
    appStore.ui.hideCatalogItemCreationModal();
    setSubmitting(false);
  };

  return (
    <div className="block-mode">
      <div className="block-mode--content">
        <div className="block-mode--header">
          <Select<IWorkspaceListItem>
            className="block-mode--workspaces-select"
            filterable={false}
            items={appStore.orgModel.workspacesList}
            popoverContentProps={{ className: "block-mode--workspaces-popover" }}
            popoverProps={{ minimal: true }}
            onItemSelect={i => setWorkspaceId(i.id)}
            itemRenderer={(item, { handleClick }) => (
              <SelectItem slug={item} key={item.id} label={item.label} onClick={handleClick} active={workspaceId === item.id} />
            )}
          >
            <Button
              fill
              icon={<CustomIcon icon={ModuleMonochromeIcon.Workspace} />}
              alignText="left"
              text={appStore.orgModel.workspacesList.find(w => w.id === workspaceId)?.label || "Select workspace"}
              rightIcon="chevron-down"
              e2eIdentifiers="select-workspace"
            />
          </Select>
          <InputGroup
            fill
            placeholder="Search Modeling block"
            leftIcon="search"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        {loading ? (
          <div className="block-mode--loader">
            <Spinner size={24} />
            Loading blocks
          </div>
        ) : (
          <>
            <FormGroup>
              <div className="block-mode--label">Select Blocks to import</div>
              <Text variant={TextVariant.Caption}>Hold shift to select all child blocks underneath a selected block</Text>
              <BlockTree className="block-mode--tree" contents={nodes} onNodeClick={handleNodeClicked} />
            </FormGroup>
          </>
        )}
      </div>
      <div className="block-mode--footer">
        <Button
          disabled={loading}
          onClick={appStore.ui.hideCatalogItemCreationModal}
          large
          minimal
          e2eIdentifiers="cancel-catalog-item-creation"
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedBlocks.length}
          large
          loading={submitting}
          className="block-mode--submit-btn"
          intent={Intent.PRIMARY}
          onClick={handleSubmit}
          e2eIdentifiers="csv-submit-catalog-item"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default observer(BlockMode);
