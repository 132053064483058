import {
  Block,
  calculateRollupResultForProperty,
  EvaluatedExpression,
  evaluateExpression,
  EvaluationError,
  getPropertyInstance,
  getRelativePropertyPath,
  isUnitsBaseEqual,
  isValidEvaluation,
  mathInstance,
  MathResult,
  optionalUnit,
  RollupPropertyInfo,
  substituteDataLinks,
  toNumber,
} from "@rollup-io/engineering";
import { DirectedGraph } from "graphology";
import { Unit } from "mathjs";

import { SuccessCriteriaType } from "@rollup-api/models";
import { IBlock } from "@store/BlockStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { AutomaticVerificationStatus, IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

import appStore from "../store/AppStore";

import { getPropertyInstanceById, getRequirementLinkedPropertyValue } from "./Properties";

// Expands or contracts a smart expression, replacing all ID entries with dot-paths or vice versa
export function formatSmartExpression(smartExpression: string | undefined, expand: boolean, referenceBlock?: IBlock | undefined) {
  if (typeof smartExpression !== "string" || !appStore.workspaceModel) {
    return undefined;
  }

  const patternRegex = /{{(.+?)}}/gm;
  const results = smartExpression.match(patternRegex);

  if (results) {
    for (const expressionToSub of results) {
      if (expressionToSub.length < 4) {
        continue;
      }

      const truncatedExpression = expressionToSub.substring(2, expressionToSub.length - 2);
      const prop = getPropertyInstance(appStore.workspaceModel, truncatedExpression, referenceBlock as Block) as IPropertyInstance;
      if (prop) {
        if (expand && referenceBlock) {
          const pathText = getShortestPath(prop, referenceBlock);
          smartExpression = smartExpression.replace(truncatedExpression, pathText ?? "");
        } else {
          smartExpression = smartExpression.replace(truncatedExpression, prop.id);
        }
      }
    }
  }

  return smartExpression;
}

export function evaluateSmartExpression(smartExpression: string | undefined, referenceProperty: IPropertyInstance) {
  if (appStore.workspaceModel) {
    if (smartExpression) {
      const res = substituteDataLinks(appStore.workspaceModel, smartExpression);
      // TODO: handle _new_ data links
      if (!res.requiredDataLinks?.length) {
        smartExpression = res.substitutedExpression;
      }
    }
    const expressionResult = evaluateExpression(appStore.workspaceModel, referenceProperty, smartExpression);
    if (expressionResult?.errorType !== EvaluationError.None) {
      return expressionResult;
    }

    // Unit checks. TODO: Clean this up a bit
    const definition = referenceProperty.propertyDefinition;

    if (definition && definition.instances?.length <= 1) {
      // Case where there's only one expression
      expressionResult.errorType = EvaluationError.None;
    } else if (
      !definition ||
      !expressionResult.calculatedResult ||
      (!expressionResult.calculatedResult.units?.length && !definition.unit)
    ) {
      // No units in either
      return expressionResult;
    } else if (expressionResult.calculatedResult.units?.length && !definition.unit) {
      // Units in result, not in definition
      expressionResult.errorType = EvaluationError.InvalidUnit;
      expressionResult.message = "Expression has units, but property definition is dimensionless.";
    } else if (definition.unit && !expressionResult.calculatedResult.units?.length) {
      // Units in definition but not in result
      expressionResult.errorType = EvaluationError.InvalidUnit;
      expressionResult.message = "Expression result is dimensionless, but property definition requires a unit.";
    } else if (!isUnitsBaseEqual(expressionResult.calculatedResult, definition.unit)) {
      expressionResult.errorType = EvaluationError.IncompatibleUnits;
      expressionResult.message = "Unit is incompatible with the definition unit.";
    }
    return expressionResult;
  }
  return undefined;
}

const generateLatexUnit = (unit?: string): string => {
  return unit ? `~\\mathrm{${unit}}` : "";
};

export const generateLatexStringFromResult = (calculatedResult?: MathResult): string => {
  if (!calculatedResult) {
    return "";
  }
  const value = toNumber(calculatedResult);
  const unit = calculatedResult?.formatUnits();
  const unitString = generateLatexUnit(unit);
  return `${value}${unitString}`;
};

export function generateLatexStringFromExpression(expression?: EvaluatedExpression) {
  if (!expression?.substitutedExpression) {
    return "";
  }
  let latexString = "";
  const validResult = isValidEvaluation(expression) && expression?.calculatedResult;

  try {
    const generatedExpression = mathInstance
      .parse(expression.substitutedExpression)
      ?.toTex({ parenthesis: "auto" })
      ?.replaceAll("cdot", "times");
    if (validResult) {
      const latexStringResult = generateLatexStringFromResult(expression.calculatedResult);
      latexString = `${generatedExpression} = ${latexStringResult}`;
    } else {
      latexString = generatedExpression ?? "";
    }
  } catch (err) {
    // only log LaTeX-generation errors when expression and result is valid
    if (validResult) {
      console.debug(validResult);
    }
  }
  return latexString;
}

export const generateLatexForZeroValue = (unit?: string) => {
  const unitString = generateLatexUnit(unit);
  return `0${unitString ? ` ${unitString}` : ""}`;
};

export const generateLatexStringForTotal = (propertyInstance: IPropertyInstance) => {
  const rollupValue = generateLatexStringFromResult(propertyInstance.rollupInfo?.calculatedResult);
  const intrinsicValue = generateLatexStringFromResult(propertyInstance.evaluatedExpressionInfo?.calculatedResult);
  const defaultIntrinsicValue = generateLatexForZeroValue(propertyInstance.combinedResult?.formatUnits());
  const result = generateLatexStringFromResult(propertyInstance.combinedResult);
  return `${intrinsicValue || defaultIntrinsicValue} + ${rollupValue} = ${result}`;
};

const getPropertyValue = (prop: IPropertyInstance): Unit => {
  const linkedPropertyValue = getRequirementLinkedPropertyValue(prop);
  if (linkedPropertyValue === undefined) {
    return optionalUnit(NaN);
  }
  return mathInstance.unit(linkedPropertyValue ?? "");
};

const getRequirementValue = (requirement: IRequirementBlock, prop: IPropertyInstance): Unit => {
  const requirementUnit = requirement.unit || prop?.effectiveUnit;
  return mathInstance.unit(`${requirement.validationFormula}${requirementUnit ? ` ${requirementUnit}` : ""}`);
};

export function validateRequirement(requirement: IRequirementBlock) {
  // TODO switch to if-statement
  switch (requirement.successCriteria) {
    case SuccessCriteriaType.Automatic: {
      let propValue: Unit | undefined;
      let reqValue: Unit | undefined;

      try {
        const prop = getPropertyInstanceById(requirement.linkedProperty);
        const isValidLinkedProp = !!prop;
        propValue = isValidLinkedProp ? getPropertyValue(prop) : undefined;
        reqValue = isValidLinkedProp ? getRequirementValue(requirement, prop) : undefined;
        requirement.setValidationMessage("");
      } catch (err: any) {
        requirement.setIsValid(AutomaticVerificationStatus.Invalid);
        requirement.setValidationMessage("Property: " + propValue + " Requirement: " + reqValue + " " + err.message);
        return;
      }

      // Check that the property:
      // - Has a linked property
      // If NOT: then set is as "not-set"
      if (requirement.linkedProperty == "") {
        requirement.setIsValid(AutomaticVerificationStatus.NotSet);
        requirement.setValidationMessage("Requirement has no linked property");
      } else if (!propValue || !reqValue) {
        requirement.setIsValid(AutomaticVerificationStatus.Invalid);
        requirement.setValidationMessage("The linked property is not valid");
      } else if (propValue.value === null) {
        requirement.setIsValid(AutomaticVerificationStatus.NotSet);
        requirement.setValidationMessage("The linked property does not have a value");
      } else if (reqValue.value === null) {
        requirement.setIsValid(AutomaticVerificationStatus.NotSet);
        requirement.setValidationMessage("The requirement does not have a value");
      } else {
        try {
          switch (requirement.validationOperation) {
            case "equals": {
              requirement.setIsValidBoolean(!!mathInstance.equal(propValue, reqValue));

              break;
            }
            case "greater-than": {
              requirement.setIsValidBoolean(!!mathInstance.larger(propValue, reqValue));
              break;
            }
            case "less-than": {
              requirement.setIsValidBoolean(!!mathInstance.smaller(propValue, reqValue));
              break;
            }
            case "greater-than-or-equal-to": {
              requirement.setIsValidBoolean(!!mathInstance.largerEq(propValue, reqValue));
              break;
            }
            case "less-than-or-equal-to": {
              requirement.setIsValidBoolean(!!mathInstance.smallerEq(propValue, reqValue));
              break;
            }
            case "not-equal-to": {
              requirement.setIsValidBoolean(!mathInstance.equal(propValue, reqValue));
              break;
            }
          }
        } catch (e: any) {
          requirement.setIsValid(AutomaticVerificationStatus.Invalid);
          requirement.setValidationMessage("Property: " + propValue + " Requirement: " + reqValue + " " + e.message);
        }
      }
    }
  }
}

export function calculateRollupValue(target: IPropertyInstance): RollupPropertyInfo {
  if (!appStore.workspaceModel) {
    return {
      errorType: EvaluationError.Unknown,
      message: "Missing workspace",
    };
  }
  return calculateRollupResultForProperty(appStore.workspaceModel, target);
}

export interface BlockNode {
  block: IBlock;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
}

export function fillHierarchicalGraph(block: IBlock, graph: DirectedGraph<BlockNode>) {
  if (!graph) {
    return false;
  }

  if (!graph.hasNode(block.id)) {
    graph.addNode(block.id, { block });
  }

  for (const child of block.validatedChildren) {
    graph.addNode(child.id, { block: child });
    graph.addEdge(block.id, child.id);
    fillHierarchicalGraph(child, graph);
  }

  return true;
}

export function getRelativePath(property: IPropertyInstance | undefined, block: IBlock | undefined) {
  if (!appStore.workspaceModel || !property || !block) {
    return undefined;
  }

  return getRelativePropertyPath(appStore.workspaceModel, property, block as Block);
}

export function getShortestPath(property: IPropertyInstance | undefined, block: IBlock | undefined) {
  if (!appStore.workspaceModel || !property || !block) {
    return undefined;
  }

  // Determine which path to display, based on length.
  const relativePath = getRelativePath(property, block);
  const absolutePath = property.path ?? "";
  return relativePath && relativePath.length < absolutePath.length ? relativePath : absolutePath;
}
