import { DialogProps as BpDialogProps } from "@blueprintjs/core";

import Dialog, { DialogSize, DialogType } from "./Dialog";

interface DialogMultiStepProps extends BpDialogProps {
  size?: DialogSize.Medium | DialogSize.Large;
  rightPane: React.ReactNode;
  footerButtons?: React.ReactNode[];
}

const DialogWithRightPane = ({ size = DialogSize.Medium, rightPane, footerButtons, ...rest }: DialogMultiStepProps) => {
  return <Dialog type={DialogType.WithRightPane} size={size} rightPane={rightPane} {...rest} footerButtons={footerButtons} />;
};

export default DialogWithRightPane;
