import { IconName } from "@blueprintjs/core";

import { StatusType } from "@store/StatusDefinitionStore";

export const STATUS_MENU_ITEMS: Array<{ icon: IconName; text: string; type: StatusType }> = [
  { icon: "italic", text: "Text", type: StatusType.text },
  { icon: "numerical", text: "Numeric", type: StatusType.number },
  { icon: "link", text: "Link", type: StatusType.url },
  { icon: "tick", text: "Check", type: StatusType.check },
  { icon: "star", text: "Single Select", type: StatusType.singleSelect },
  { icon: "multi-select", text: "Multi Select", type: StatusType.multiSelect },
  { icon: "person", text: "Mention", type: StatusType.mention },
  { icon: "calendar", text: "Date", type: StatusType.date },
];

export const getProjectStatusIcon = (statusType: StatusType): string => {
  const item = STATUS_MENU_ITEMS.find(item => item.type === statusType);
  return item?.icon || "";
};
