import { useCallback } from "react";
import { NavigateOptions, useNavigate, useParams } from "react-router-dom";

import appStore from "@store/AppStore";
import { EBlockTab } from "@store/BlockStore";
import { CommentDisplayParentType } from "@store/CommentStore";
import { EntityType, StoreType } from "@store/types";
import {
  getKnowledgebaseBlockByIdAsync,
  getKnowledgebasePageByIdAsync,
  getPropertyInstanceByIdAsync,
  getRequirementBlockByIdAsync,
  getRequirementsPageByIdAsync,
} from "@utilities";
import { getAnnotationById } from "@utilities/AnnotationUtils";
import { getAttachmentById } from "@utilities/Attachment";
import { getWorkspaceLink } from "@utilities/LinkUtils";

export enum EPageName {
  Analysis = "analysis",
  Bom = "bom",
  Blocks = "blocks",
  CustomUnits = "custom-units",
  Configuration = "configuration",
  DataConnections = "data-connections",
  Hierarchy = "hierarchy",
  Imports = "imports",
  Inbox = "inbox",
  Knowledgebase = "kb",
  Modeling = "modeling",
  Settings = "settings",
  Pdm = "pdm",
  ItemCatalog = "item-catalog",
  ProjectManagement = "project-management",
  ProjectManagementMyTasks = "my-tasks",
  ProjectManagementAllTasks = "tasks",
  ProjectManagementProjects = "projects",
  Properties = "properties",
  PropertyRelations = "dag",
  // @deprecated
  Reports = "reports",
  Requirements = "requirements",
  Table = "table",
  Threads = "threads",
  Statuses = "statuses",
}

export const entityTypeToPageMap: { [key in EntityType]?: string } = {
  [EntityType.Block]: `${EPageName.Modeling}/${EPageName.Blocks}`,
  [EntityType.BomTable]: `${EPageName.Pdm}/${EPageName.Bom}`,
  [EntityType.CodeBlock]: EPageName.Analysis,
  [EntityType.Report]: EPageName.Knowledgebase,
  [EntityType.RequirementsDocument]: EPageName.Requirements,
};

/** Hooks. */
export function useAppNavigate() {
  const navigate = useNavigate();
  const { workspaceId: currentWorkspaceId } = useParams();

  const navigateToOrgLevelHoopsViewer = (attachmentId: string) => {
    navigate(`/hoops/${attachmentId}`);
  };

  const navigateToWorkspace = (workspaceId: string | undefined = currentWorkspaceId, options?: NavigateOptions) => {
    if (!currentWorkspaceId && !workspaceId) {
      console.error("Missing workspaceId");
      return false;
    }
    navigate(`/workspaces/${workspaceId}`, options);
    return true;
  };

  const navigateToBlock = useCallback(
    (blockId?: string, workspaceId?: string, options?: NavigateOptions) => {
      if ((!currentWorkspaceId && !workspaceId) || !blockId) {
        console.error("Missing workspaceId or blockId");
        return false;
      }
      appStore.env.clearActiveAttachment();
      appStore.env.clearActivePropertyInstance();
      navigate(`/workspaces/${workspaceId || currentWorkspaceId}/modeling/blocks/${blockId}`, options);
      return true;
    },
    [currentWorkspaceId, navigate]
  );

  const getBlockLink = (blockId: string, workspaceId = currentWorkspaceId) => {
    if (!workspaceId) {
      return "#";
    }

    return `${getWorkspaceLink(workspaceId)}/${EPageName.Modeling}/${EPageName.Blocks}/${blockId}`;
  };

  const navigateToProperty = (propertyId: string, workspaceId?: string, options?: NavigateOptions) => {
    if (!currentWorkspaceId && !workspaceId) {
      console.error("Missing workspaceId");
      return false;
    }

    const propertyInstance = appStore.workspaceModel?.propertyInstanceMap.get(propertyId);

    if (!propertyInstance) {
      console.error("Missing Property instance");
      return false;
    }

    navigate(
      `/workspaces/${workspaceId || currentWorkspaceId}/modeling/blocks/${propertyInstance.parentBlock.id}#pid=${propertyId}`,
      options
    );
    return true;
  };

  const navigateToDataConnection = (id: string, workspaceId?: string) => {
    navigate(`/workspaces/${workspaceId || currentWorkspaceId}/${EPageName.DataConnections}?id=${id}`);
    return true;
  };

  const navigateToPropertyDependency = (propertyId: string, workspaceId?: string, options?: NavigateOptions) => {
    if (!currentWorkspaceId && !workspaceId) {
      console.error("Missing workspaceId");
      return false;
    }

    navigate(`/workspaces/${workspaceId || currentWorkspaceId}/modeling/threads/dag/${propertyId}`, options);
    return true;
  };

  const navigateToAttachment = (attachmentId: string, workspaceId?: string, options?: NavigateOptions) => {
    if (!currentWorkspaceId && !workspaceId) {
      console.error("Missing workspaceId");
      return false;
    }

    const attachment = appStore.workspaceModel?.attachments.get(attachmentId);

    if (!attachment) {
      console.error("Missing Attachment instance");
      return false;
    }

    navigate(`/workspaces/${workspaceId || currentWorkspaceId}/modeling/blocks/${attachment.block.id}/#aid=${attachmentId}`, options);
    return true;
  };

  const getAttachmentLink = (attachmentId: string, workspaceId = currentWorkspaceId) => {
    const attachment = appStore.workspaceModel?.attachments.get(attachmentId);

    if (!workspaceId || !attachment) {
      return "#";
    }

    return `${getWorkspaceLink(workspaceId)}/modeling/blocks/${attachment.block.id}/#aid=${attachmentId}`;
  };

  const navigateToAnnotation = async (annotationId: string, workspaceId: string, commentId?: string, options?: NavigateOptions) => {
    const annotation = await getAnnotationById(annotationId);
    if (!annotation) {
      console.warn(`Annotation ${annotationId} not found`);
      return;
    }
    const getQueryParams = () => {
      const commIdParam = commentId ? `&commId=${commentId}` : "";
      return `?annId=${annotationId}${commIdParam}`;
    };
    switch (annotation.parentType) {
      case StoreType.Attachment: {
        const attachment = await getAttachmentById(annotation.parentId, workspaceId);
        if (!attachment) {
          console.warn(`Attachment ${annotation.parentId} not found`);
          return;
        }
        navigate(`/workspaces/${workspaceId}/modeling/blocks/${attachment.block.id}${getQueryParams()}#aid=${attachment.id}`, options);
        return true;
      }
      case StoreType.Block:
        // TODO: implement
        break;
      case StoreType.ReportBlock: {
        const kbBlock = await getKnowledgebaseBlockByIdAsync(annotation.parentId, workspaceId);
        if (!kbBlock) {
          console.warn(`Knowledgebase Block ${annotation.parentId} not found`);
          return;
        }
        navigate(`/workspaces/${workspaceId}/${EPageName.Knowledgebase}/${kbBlock.parentReport.id}${getQueryParams()}`, options);
        break;
      }
      case StoreType.Report: {
        const kbPage = await getKnowledgebasePageByIdAsync(annotation.parentId, workspaceId);
        if (!kbPage) {
          console.warn(`Knowledgebase Page ${annotation.parentId} not found`);
          return;
        }
        navigate(`/workspaces/${workspaceId}/${EPageName.Knowledgebase}/${kbPage.id}${getQueryParams()}`, options);
        return true;
      }
      case StoreType.RequirementBlock: {
        const reqBlock = await getRequirementBlockByIdAsync(annotation.parentId, workspaceId);
        if (!reqBlock) {
          console.warn(`Requirement Block ${annotation.parentId} not found`);
          return;
        }
        const reqPage = reqBlock.parentPage && (await getRequirementsPageByIdAsync(reqBlock.parentPage.id, workspaceId));
        if (reqPage) {
          reqPage.setLastFocusedCommentId("");
          navigate(`/workspaces/${workspaceId}/${EPageName.Requirements}/${reqPage.id}${getQueryParams()}`, options);
        }
        return true;
      }
      case StoreType.Requirement: {
        const reqPage = await getRequirementsPageByIdAsync(annotation.parentId, workspaceId);
        if (!reqPage) {
          console.warn(`Requirements Page ${annotation.parentId} not found`);
          return;
        }
        navigate(`/workspaces/${workspaceId}/${EPageName.Requirements}/${reqPage.id}${getQueryParams()}`, options);
        return true;
      }
      case StoreType.PropertyInstance: {
        const propertyInstance = await getPropertyInstanceByIdAsync(annotation.parentId, workspaceId);
        if (!propertyInstance) {
          console.warn(`Property Instance ${annotation.parentId} not found`);
          return;
        }
        propertyInstance.setLastFocusedCommentId("");
        navigate(
          `/workspaces/${workspaceId}/modeling/blocks/${propertyInstance.parentBlock.id}${getQueryParams()}#pid=${propertyInstance.id}`,
          options
        );
        return true;
      }
      default:
        console.warn(`Unknown parentType ${annotation.parentType}`);
    }
  };

  const navigateToReport = (reportId?: string, workspaceId = currentWorkspaceId, options?: NavigateOptions) => {
    appStore.env.clearActiveReportBlock();
    if ((!currentWorkspaceId && !workspaceId) || !reportId) {
      console.error("Missing workspaceId or pageId");
      return false;
    }
    navigate(`/workspaces/${workspaceId}/${EPageName.Knowledgebase}/${reportId}`, options);
    return true;
  };

  const navigateToRequirement = (
    requirementId?: string,
    workspaceId: string | undefined = currentWorkspaceId,
    options?: NavigateOptions
  ) => {
    if ((!currentWorkspaceId && !workspaceId) || !requirementId) {
      console.error("Missing workspaceId or requirementId");
      return false;
    }
    navigate(`/workspaces/${workspaceId}/${EPageName.Requirements}/${requirementId}`, options);
    return true;
  };

  const navigateToRequirementSettings = (workspaceId = currentWorkspaceId) => {
    navigate(`/workspaces/${workspaceId}/${EPageName.Requirements}/${EPageName.Settings}`);
  };

  const navigateToImports = (workspaceId = currentWorkspaceId) => {
    navigate(`workspaces/${workspaceId}/imports`);
  };

  const navigateToIntegrationsPage = () => {
    navigate(`/settings/marketplace`);
    return true;
  };

  const navigateToIntegrationOverview = (id: string) => {
    navigate(`/settings/integrations/${id}`);
    return true;
  };

  const navigateToIntegrationConfig = (id: string) => {
    navigate(`/settings/integrations/${id}/config`);
    return true;
  };

  const navigateToInternalIntegration = (internalIntegration: string) => {
    // todo check if passed integration is known internal integration
    navigate(`/settings/integration/internal/${internalIntegration}`);
    return true;
  };

  const navigateToPlanAndBilling = () => {
    navigate(`/settings/plan-and-billing`);
    return true;
  };

  const navigateToDiscussionFromDisplayParent = (
    displayParentType: CommentDisplayParentType,
    displayParentId: string,
    workspaceId: string,
    commentId?: string
  ) => {
    switch (displayParentType) {
      case CommentDisplayParentType.Annotation:
        navigateToAnnotation(displayParentId, workspaceId, commentId);
        break;
      case CommentDisplayParentType.Block:
        navigateToBlock(displayParentId, workspaceId);
        appStore.env.setCurrentBlockTab(EBlockTab.COMMENTS);
        break;
      case CommentDisplayParentType.Attachment:
      case CommentDisplayParentType.Report:
        navigateToReport(displayParentId, workspaceId);
        break;
      case CommentDisplayParentType.Requirement:
        navigateToRequirement(displayParentId, workspaceId);
        break;
      case CommentDisplayParentType.BOM:
        // TODO: implement
        break;
      default:
        console.warn("Unknown display parent type", displayParentType);
    }
  };

  const navigateToDataConnections = (workspaceId = currentWorkspaceId) => {
    if (!workspaceId) {
      console.error("Missing workspaceId");
      return false;
    }
    navigate(`/workspaces/${workspaceId}/${EPageName.DataConnections}`);
  };

  const navigateToCodeBlock = (blockId?: string, workspaceId?: string, options?: NavigateOptions) => {
    if ((!currentWorkspaceId && !workspaceId) || !blockId) {
      console.error("Missing workspaceId or blockId");
      return false;
    }
    navigate(`/workspaces/${workspaceId || currentWorkspaceId}/${EPageName.Analysis}/${blockId}`, options);
    return true;
  };

  const navigateToPdmSettings = (workspaceId = currentWorkspaceId) => {
    navigate(`/workspaces/${workspaceId}/${EPageName.Pdm}/${EPageName.Settings}`);
  };

  const getPropertyLink = (propertyId: string, workspaceId = currentWorkspaceId) => {
    const propertyInstance = appStore.workspaceModel?.propertyInstanceMap.get(propertyId);
    if (!propertyInstance || !workspaceId) {
      return "#";
    }

    const wsLink = getWorkspaceLink(workspaceId);
    return `${wsLink}/modeling/blocks/${propertyInstance.parentBlock.id}#pid=${propertyId}`;
  };

  return {
    navigateToAttachment,
    navigateToAnnotation,
    navigateToWorkspace,
    navigateToBlock,
    navigateToCodeBlock,
    navigateToReport,
    navigateToRequirement,
    navigateToProperty,
    navigateToPropertyDependency,
    navigateToInternalIntegration,
    navigateToIntegrationOverview,
    navigateToIntegrationConfig,
    navigateToIntegrationsPage,
    navigateToImports,
    navigateToDiscussionFromDisplayParent,
    navigateToDataConnections,
    navigateToPdmSettings,
    navigateToOrgLevelHoopsViewer,
    navigateToPlanAndBilling,
    navigateToDataConnection,
    navigateToRequirementSettings,
    getPropertyLink,
    getBlockLink,
    getAttachmentLink,
  };
}
