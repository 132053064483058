import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";

import AnalysisPage from "../AnalysisPage/AnalysisPage";

/** Main Function. */
function AnalysisFrame() {
  const { codeBlockId, analysisId } = useParams(); // TODO: investigate for spreadsheet case
  const workspace = useWorkspace();

  const id = codeBlockId || analysisId; // Use whichever is present
  const analysis = id ? workspace.analysis.analysisMap.get(id) : undefined;

  useEffect(() => {
    if (analysis) {
      appStore.env.setActiveAnalysis(analysis.id);
    }
  }, [analysis]);

  if (!analysis) {
    return null;
  }

  return <AnalysisPage analysis={analysis} />;
}

/** Exports. */
export default observer(AnalysisFrame);
