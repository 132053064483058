import { EPageName } from "@router/hooks/useAppNavigate";

import OrgLevelFeatureLayout from "./OrgLevelFeatureLayout";
import PmInnerLayout from "./PmInnerLayout";

const InboxLayout = () => {
  return (
    <OrgLevelFeatureLayout currentPage={EPageName.ProjectManagement}>
      <PmInnerLayout />
    </OrgLevelFeatureLayout>
  );
};

export default InboxLayout;
