import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";

import styles from "./SidePanelBackButton.module.scss";

interface ISidePanelBackButtonProps {
  label: string;
  className?: string;
  onClick(): void;
}

const SidePanelBackButton = (props: ISidePanelBackButtonProps) => {
  const { label, className, onClick } = props;

  return (
    <Button
      className={classNames(styles.sidePanelBackButton, className)}
      e2eIdentifiers="back-button"
      alignText="left"
      icon="arrow-left"
      onClick={onClick}
      ellipsizeText
      minimal
      large
      fill
    >
      {label}
    </Button>
  );
};

export default observer(SidePanelBackButton);
