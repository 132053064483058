import assignIn from "lodash/assignIn";
import { flow, IAnyModelType, Instance, isAlive, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { BlockIcon } from "@components/CustomIcon";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { CatalogItemRemoved } from "@rollup-api/models/catalogItem/catalogItem.model";
import {
  CatalogItemSource,
  CatalogItemStatus,
  CatalogItemType,
  CreateCatalogItemDto,
  UpdateCatalogItemDto,
} from "@rollup-api/models/catalogItem/catalogItemDtos";
import { SemanticRevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import appStore from "@store/AppStore";
import { CatalogItemReferenceStore, ICatalogItemReference } from "@store/CatalogItem/CatalogItemReferenceStore";
import { CatalogItemVersionStore, ICatalogItemVersion } from "@store/CatalogItem/CatalogItemVersionStore";
import { IPartNumberSchema } from "@store/PartNumberSchemaStore";
import { validatedRefArray } from "@utilities";
import { getCatalogItemPath, getNextRevisionCode, getStatusLabel } from "@utilities/CatalogItem";
import { rollupClient } from "src/core/api";

export const CatalogItemStore = types
  .model("CatalogItem", {
    id: types.identifier,
    parentItem: types.maybeNull(types.safeReference(types.late((): IAnyModelType => CatalogItemStore))),
    mainAttachmentId: types.maybeNull(types.string),
    workspaceId: types.maybeNull(types.string),
    versions: types.array(types.safeReference(types.late((): IAnyModelType => CatalogItemVersionStore))),
    status: types.maybeNull(types.enumeration("CatalogItemStatus", [...Object.values(CatalogItemStatus)])),
    children: types.array(types.safeReference(types.late((): IAnyModelType => CatalogItemStore))),
    partNumber: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    name: types.string,
    type: types.enumeration("CatalogItemType", [...Object.values(CatalogItemType)]),
    source: types.maybeNull(types.enumeration("CatalogItemSource", [...Object.values(CatalogItemSource)])),
    createdBy: types.string,
    references: types.array(types.safeReference(types.late((): IAnyModelType => CatalogItemReferenceStore))),
    childReferences: types.array(types.safeReference(types.late((): IAnyModelType => CatalogItemReferenceStore))),
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
  })
  .views(self => ({
    get aliveReferences(): ICatalogItemReference[] {
      return self.references.filter(isAlive);
    },
    get aliveChildReferences(): ICatalogItemReference[] {
      return self.childReferences.filter(isAlive);
    },
    get sortedVersions(): ICatalogItemVersion[] {
      return self.versions.slice().sort((a, b) => b.createdAt - a.createdAt);
    },
    get validatedChildren() {
      return validatedRefArray<ICatalogItem>(self.children);
    },
    get hasChildren(): boolean {
      return !!this.validatedChildren.length;
    },
    get isRoot(): boolean {
      return !self.parentItem;
    },
    get icon() {
      const hasRefs = !appStore.ui.showPdmFlatten && self.references.length;
      if (self.type === CatalogItemType.Product) {
        return BlockIcon.Block;
      }

      if (self.type === CatalogItemType.Assembly || hasRefs || this.hasChildren) {
        return BlockIcon.Assembly;
      }

      return BlockIcon.Part;
    },
  }))
  .actions(self => ({
    patch: (dto: UpdateCatalogItemDto) => {
      try {
        assignIn(self, dto);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    updateCatalogItem: flow(function* (updateDto: UpdateCatalogItemDto, field = "catalog item"): Generator<any, boolean, any> {
      try {
        yield appStore.orgModel.catalogItems.updateCatalogItem(self.id, updateDto);
        return true;
      } catch (err) {
        console.warn(err);
        showApiErrorToast(`Failed to update ${field}`);
        return false;
      }
    }),
  }))
  .views(self => ({
    get pathIds(): string[] {
      return getCatalogItemPath(self as ICatalogItem);
    },
    get latestRevision(): ICatalogItemVersion | undefined {
      const revisions = self.sortedVersions.filter(version => !!version.revisionCode && !version.isDraft);

      return revisions.at(0);
    },
    get mainVersion(): ICatalogItemVersion | undefined {
      return self.sortedVersions.find(version => version.attachmentId === self.mainAttachmentId) ?? this.latestVersion;
    },
    get latestVersion(): ICatalogItemVersion | undefined {
      return self.sortedVersions.at(0);
    },
    get latestNonDraftVersion(): ICatalogItemVersion | undefined {
      const nonDraftVersions = self.sortedVersions.filter(version => !version.isDraft);

      return nonDraftVersions.at(0);
    },
    get revisions(): ICatalogItemVersion[] {
      return self.versions.filter(version => !!version.revisionCode && !version.isDraft);
    },
  }))
  .views(self => ({
    get weight() {
      return self.latestNonDraftVersion?.weight || 0;
    },
    get comment() {
      return self.latestNonDraftVersion?.comment || "";
    },
    get weightUnit() {
      return self.latestNonDraftVersion?.weightUnit || "kg";
    },
    get material() {
      return self.latestNonDraftVersion?.material || "";
    },
    get supplier() {
      return self.latestNonDraftVersion?.supplier || "";
    },
    get version() {
      return self.latestNonDraftVersion?.index || "0";
    },
    get cost() {
      return self.latestNonDraftVersion?.cost || 0;
    },
    get costCurrency() {
      return self.latestNonDraftVersion?.costCurrency || "$";
    },
    get quantity() {
      return self.latestNonDraftVersion?.quantity || 0;
    },
    get leadTime() {
      return self.latestNonDraftVersion?.leadTimeHumanized;
    },
    get revisionName() {
      return self.latestNonDraftVersion?.revisionName || "";
    },
    get revisionCode() {
      return self.latestNonDraftVersion?.revisionCode || "";
    },
    get statusLabel() {
      return self.status ? getStatusLabel(self.status) : "";
    },
    get weightString() {
      return this.weight ? `${this.weight} ${this.weightUnit}` : "";
    },
    get costString() {
      return this.cost ? `${this.cost} ${this.costCurrency}` : "";
    },
  }))
  .actions(self => ({
    nextRevisionCode(updateType: SemanticRevisionCodeType): string {
      return getNextRevisionCode(
        self.revisions.length,
        appStore.orgModel.catalogItems.catalogItemRevisionCodeType,
        self.latestRevision?.revisionCode || "",
        updateType
      );
    },
    setMainAttachmentId: flow(function* setMainAttachmentId(mainAttachmentId: string): Generator<any, boolean, boolean> {
      if (mainAttachmentId === self.mainAttachmentId) {
        return true;
      }

      self.mainAttachmentId = mainAttachmentId;

      return yield self.updateCatalogItem({ mainAttachmentId }, "main attachment id");
    }),
    setImageUrl: flow(function* setImageUrl(url: string): Generator<any, boolean, boolean> {
      if (url === self.imageUrl) {
        return true;
      }

      self.imageUrl = url;

      return yield self.updateCatalogItem({ imageUrl: url }, "image");
    }),
    setSource: flow(function* setSource(source: CatalogItemSource): Generator<any, boolean, boolean> {
      if (source === self.source) {
        return true;
      }

      self.source = source;

      return yield self.updateCatalogItem({ source }, "catalog item source");
    }),
    setPartNumberFromSchema: flow(function* setPartNumberFromSchema(schema: IPartNumberSchema): Generator<any, boolean, any> {
      try {
        const newPartNumber: string | undefined = yield rollupClient.catalogItems.setPartNumberFromSchema(self.id, schema.id);

        if (newPartNumber !== undefined) {
          self.partNumber = `${newPartNumber}`;
          return true;
        }

        return false;
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Failed to set part number from schema");
        return false;
      }
    }),
    setPartNumber: flow(function* setPartNumber(partNumber: string): Generator<any, boolean, boolean> {
      if (partNumber === self.partNumber) {
        return true;
      }

      self.partNumber = partNumber;

      return yield self.updateCatalogItem({ partNumber }, "part number");
    }),
    setName: flow(function* setName(name: string): Generator<any, boolean, boolean> {
      if (name === self.name) {
        return true;
      }

      self.name = name;

      return yield self.updateCatalogItem({ name }, "part name");
    }),
    setStatus: flow(function* setStatus(status: CatalogItemStatus): Generator<any, boolean, boolean> {
      if (status === self.status) {
        return true;
      }

      self.status = status;

      return yield self.updateCatalogItem({ status }, "status");
    }),
    setDescription: flow(function* setDescription(description: string): Generator<any, boolean, boolean> {
      if (description === self.description) {
        return true;
      }

      self.description = description;

      return yield self.updateCatalogItem({ description }, "description");
    }),
    setType: flow(function* setType(type: CatalogItemType): Generator<any, boolean, boolean> {
      if (type === self.type) {
        return true;
      }

      self.type = type;

      return yield self.updateCatalogItem({ type }, "catalog item type");
    }),
  }));

export interface ICatalogItem extends Instance<typeof CatalogItemStore> {}

export interface ICatalogItemSnapshotIn extends SnapshotIn<typeof CatalogItemStore> {}

interface ICatalogItemSnapshotOut extends SnapshotOut<typeof CatalogItemStore> {}

export interface ICatalogItemMobxType extends IType<ICatalogItemSnapshotIn, ICatalogItemSnapshotOut, ICatalogItem> {}

export function subscribeToCatalogItemEvents(socket: Socket) {
  socket.on("createCatalogItem", (data: { createCatalogItemDto: CreateCatalogItemDto }) => {
    if (data.createCatalogItemDto.id) {
      // check if the catalog item already exists
      // create version
      // create item
      // link version to item
      // put item & version to the store
    }
  });

  socket.on("updateCatalogItem", (data: { id: string; updateCatalogItemDto: UpdateCatalogItemDto }) => {
    if (data.id) {
      const item = appStore.orgModel.catalogItems.getCatalogItem(data.id);
      item?.patch(data.updateCatalogItemDto);
    }
  });

  socket.on("deleteCatalogItem", (data: { orgId: string; removedCatalogItem: CatalogItemRemoved }) => {
    if (data.orgId && appStore.orgModel.info.id === data.orgId) {
      appStore.orgModel.catalogItems.removeExistingCatalogItem(data.removedCatalogItem);
    }
  });

  socket.on("deleteCatalogItems", (data: { orgId: string; removedCatalogItems: CatalogItemRemoved[] }) => {
    if (data.orgId && appStore.orgModel.info.id === data.orgId) {
      data.removedCatalogItems.forEach(item => {
        appStore.orgModel.catalogItems.removeExistingCatalogItem(item);
      });
    }
  });
}
