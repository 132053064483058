import { useEffect } from "react";
import { Intent, Menu, Spinner } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { PopupMenu } from "@components/Shared/PopupMenu";
import { IIntegration } from "@store/IntegrationStore";

import "./ConnectIntegrationCard.scss";

interface IConnectIntegrationCardProps {
  integration: IIntegration;
  description: string;
  skipAuth?: boolean;
}

const ConnectIntegrationCard = (props: IConnectIntegrationCardProps) => {
  const { integration, description, skipAuth } = props;

  useEffect(() => {
    integration?.refreshAuthStatus();
  }, [integration, integration.connection]);

  const renderConnectionIndicator = () => {
    const isCheckingAuth = integration.isConnected && integration.isAuthorized === undefined;
    if (integration.isConnected) {
      if (isCheckingAuth) {
        return <Spinner size={16} />;
      } else if (integration.isAuthorized || skipAuth) {
        return (
          <>
            <BlueprintIcon className="connect-integration-card--tick-icon" icon="tick" />
            <span>Connected</span>
          </>
        );
      } else {
        return (
          <>
            <BlueprintIcon className="connect-integration-card--warning-icon" icon="warning-sign" />
            <span>Authorization revoked</span>
          </>
        );
      }
    }
  };

  return (
    <div className="connect-integration-card">
      <div className="connect-integration-card--header">
        <div className="connect-integration-card--container">
          {integration.logoUrl && <img src={integration.logoUrl} alt={integration.name} width={24} height={24} />}
          Connect {integration.name}
        </div>
        <div className="connect-integration-card--container">
          {renderConnectionIndicator()}
          {integration.isConnected ? (
            <PopupMenu
              buttonProps={{ icon: "more", minimal: true }}
              content={
                <Menu>
                  {!integration.isAuthorized && !skipAuth && (
                    <MenuItem
                      icon="refresh"
                      text="Reauthorize"
                      onClick={integration.reauthorize}
                      intent={Intent.PRIMARY}
                      e2eIdentifiers="reauthorize"
                    />
                  )}
                  <MenuItem
                    icon="unlink"
                    text="Disconnect"
                    e2eIdentifiers="disconnect"
                    intent={Intent.WARNING}
                    onClick={integration.disconnect}
                  />
                </Menu>
              }
              e2eIdentifiers="open-menu"
            />
          ) : (
            <Button onClick={integration.connect} minimal e2eIdentifiers="connect" intent={Intent.PRIMARY}>
              Connect
            </Button>
          )}
        </div>
      </div>
      <div className="connect-integration-card--content">{description}</div>
    </div>
  );
};

export default observer(ConnectIntegrationCard);
