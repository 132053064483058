import { AxiosResponse } from "axios";

import { ICreateProjectTaskDto, IUpdateProjectTaskDto } from "@rollup-api/models/pm/project-task.dto";
import { ProjectTask } from "@rollup-api/models/pm/project-task.model";
import { trackSegmentEvent } from "src/lib/Segment";

import { HttpClient, ParentClient } from "../client";

export class ProjectTasks extends HttpClient {
  public constructor(parent: ParentClient) {
    super(parent);
  }

  private endpoint(projectId: string) {
    return `projects/${projectId}/tasks`;
  }

  public getAll = (projectId: string): Promise<AxiosResponse<ProjectTask[]>> => {
    return this.instance.get<ProjectTask[]>(`${this.endpoint(projectId)}`);
  };

  public create = (projectId: string, dto: ICreateProjectTaskDto): Promise<AxiosResponse<ProjectTask>> => {
    trackSegmentEvent("projectTask:create", { projectId, ...dto });
    return this.instance.post<ProjectTask>(`${this.endpoint(projectId)}`, dto);
  };

  public delete = (projectId: string, taskId: string): Promise<AxiosResponse<ProjectTask>> => {
    trackSegmentEvent("projectTask:delete", { projectId, taskId });
    return this.instance.delete<ProjectTask>(`${this.endpoint(projectId)}/${taskId}`);
  };

  public update = (projectId: string, taskId: string, dto: IUpdateProjectTaskDto): Promise<AxiosResponse<ProjectTask>> => {
    trackSegmentEvent("projectTask:update", { projectId, taskId, ...dto });
    return this.instance.put<ProjectTask>(`${this.endpoint(projectId)}/${taskId}`, dto);
  };
}
