import { Intent } from "@blueprintjs/core";
import { Editor } from "@tiptap/react";
import { cast, destroy, getSnapshot, IAnyModelType, Instance, isAlive, types } from "mobx-state-tree";

import { CatalogItemTableGridApi } from "@components/CatalogItems/Table/types";
import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { TableViewGridApi } from "@components/Modeling/ModelingFrame/Table/TableComponent/types";
import { defaultTableViewConfigTabId } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import { ReportTreeGridApi } from "@components/ReportsTree/types";
import { RequirementsViewMode } from "@components/Requirements/Requirements.types";
import { RequirementsTableGridApi } from "@components/Requirements/RequirementsTable/types";
import { showToast } from "@components/UiLayers/toaster";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { IconSource, IIcon } from "@rollup-types/icons";
import { ISelectedPlanData } from "@router/elements/settings/SettingsPlanAndBilling/types";
import { ColumnsOrderStore } from "@store/ColumnsOrderStore";
import { ColumnsSizeStore } from "@store/ColumnsSizeStore";
import { FeatureFlagsStore } from "@store/FeatureFlagsStore";
import { InboxSettingsStore } from "@store/InboxSettingsStore";
import { PdmCardsVisibilityStore } from "@store/PdmCardsVisibilityStore";
import { IPropertyInstance, IPropertyInstanceMobxType, PropertyInstanceStore } from "@store/PropertyInstanceStore";
import { EntityType } from "@store/types";
import { copyToClipboard, EEntityShortcut, FilePreviewType, getBlockById, getEntityTypeByHash, getPreviewType } from "@utilities";
import { rollupClient } from "src/core/api";

import { IWorkspaceListItem } from "../services";

import appStore from "./AppStore";
import { AttachmentStore, IAttachment, IAttachmentMobxType } from "./AttachmentStore";
import { BlockStore, EBlockTab, IBlock } from "./BlockStore";
import { HoopsViewerStore, IHoopsViewer } from "./HoopsViewerStore";
import { PaneWidthStore } from "./PaneWidthStore";
import { BlockSidePanel, ReqSidePanel, SidePanelStore } from "./SidePanelStore";
import { IStatusInstance } from "./StatusInstanceStore";
import { TableViewStore } from "./TableViewStore";

const MAX_RECENT_ICONS = 12;

interface IRecentEntity {
  id: string;
  type: EntityType;
}

export enum SettingsActiveTab {
  properties = "properties",
  statuses = "statuses",
}

export enum ModelingActiveTab {
  model = "model",
  table = "table",
  settings = "settings",
  flow = "flow",
  debugging = "debugging",
}

export enum DrawerActiveTab {
  execution = "execution",
  problems = "problems",
  changeLog = "change-log",
  integrations = "integrations",
}

export enum HoopsTreePanelState {
  Open = "open",
  Closed = "closed",
}

export enum HoopsSidePanelState {
  Closed = "closed",
  Annotations = "annotations",
  SavedViews = "savedViews",
  ModelDetails = "modelDetails",
}

export enum FeedSidePanelState {
  Comments = "comments",
  ActionHistory = "actionHistory",
}

export enum RequirementsActiveTab {
  requirements = "requirements",
  settings = "settings",
}

export enum BomTablesActiveTab {
  bomTables = "bomTables",
  settings = "settings",
}

export enum Theme {
  Light = "Light",
  Dark = "Dark",
  System = "System",
}

const RECENT_ENTITIES_LIMIT = 250;

interface IEnvironmentStoreVolatile {
  attachmentViewer?: IHoopsViewer;
  attachmentSubViewer?: IHoopsViewer;
  detailedAttachment?: IAttachment;
  tableViewGridApi?: TableViewGridApi;
  reportsTreeGridApi?: ReportTreeGridApi;
  blocksTreeGridApi?: TableViewGridApi;
  catalogItemTableGridApi?: CatalogItemTableGridApi;
  requirementsTableGridApi?: RequirementsTableGridApi;
  editors: Map<string, Editor>;
  dependencyGraphVisible: boolean;
  pinnedPropertiesVisible: boolean;
  detachedExpressionEditorVisible: boolean;
  pinnedProperties: Array<IPropertyInstance>;
  loadingTheme: boolean;
  selectedBillingPlan?: ISelectedPlanData;
}

export const EnvironmentStore = types
  .model("Environment", {
    columnsOrder: types.optional(ColumnsOrderStore, getSnapshot(ColumnsOrderStore.create())),
    columnsSize: types.optional(ColumnsSizeStore, {
      requirementsTable: {},
      catalogItemsTable: {},
    }),
    pdmCardVisibility: types.optional(PdmCardsVisibilityStore, getSnapshot(PdmCardsVisibilityStore.create())),
    paneWidth: types.optional(PaneWidthStore, () => PaneWidthStore.create()),
    isNavPaneOpen: types.optional(types.boolean, true),
    isSubNavPaneOpen: types.optional(types.boolean, true),
    showPdmThumbnail: types.optional(types.boolean, true),
    bomTableSize: types.optional(types.number, 0),
    catalogItemTableSize: types.optional(types.number, 0),
    theme: types.optional(types.enumeration([...Object.values(Theme)]), Theme.System),
    systemTheme: types.optional(types.enumeration([Theme.Light, Theme.Dark]), Theme.Dark),
    leftSidebarIsCollapsed: types.boolean, // 3
    rightSidebarIsCollapsed: types.boolean, // 3
    debugDrawerIsOpen: types.optional(types.boolean, false),
    showSmartAnnotations: types.optional(types.boolean, true),
    hoopsTreePanelState: types.optional(types.enumeration([...Object.values(HoopsTreePanelState)]), HoopsTreePanelState.Open),
    hoopsSidePanelState: types.optional(types.enumeration([...Object.values(HoopsSidePanelState)]), HoopsSidePanelState.Closed),
    feedSidePanelState: types.optional(types.enumeration([...Object.values(FeedSidePanelState)]), FeedSidePanelState.Comments),
    drawerHeight: types.optional(types.number, 0.4),
    tabulatedViewDefaultShowSingle: types.optional(types.boolean, true),
    tabulatedViewDefaultShowMultiplicity: types.optional(types.boolean, true),
    tabulatedViewDefaultShowEmpty: types.optional(types.boolean, true),
    tabulatedViewShowProjectStatuses: types.optional(types.boolean, true),
    activePropertyShouldFocus: types.optional(types.boolean, false), // for ribbon
    activeReportId: types.maybeNull(types.string),
    activeReportBlockId: types.maybeNull(types.string),
    activeDocumentBlockId: types.maybeNull(types.string),
    activeBomTableId: types.maybeNull(types.string),
    activeTableViewConfigId: types.optional(types.string, defaultTableViewConfigTabId),
    activeRequirementsDocumentId: types.maybeNull(types.string),
    activeAnalysisId: types.maybeNull(types.string),
    settingShowEngineeringView: types.optional(types.boolean, false),
    debugMode: types.optional(types.boolean, false), // false before deploy
    horizontalLayoutSizeLeft: types.optional(types.number, 0.5),
    errorString: types.optional(types.string, ""),
    acknowledgedFormulaProperty: types.optional(types.boolean, false),
    acknowledgeCodeCard: types.optional(types.boolean, false),
    featureFlags: types.optional(FeatureFlagsStore, {}),
    tableView: types.optional(TableViewStore, {}),
    sidePanel: types.optional(SidePanelStore, {}),
    drawerActiveTab: types.optional(types.enumeration([...Object.values(DrawerActiveTab)]), DrawerActiveTab.execution),
    modelingLeftActiveTab: types.optional(types.enumeration([...Object.values(ModelingActiveTab)]), ModelingActiveTab.model),
    settingsActiveTab: types.optional(types.enumeration([...Object.values(SettingsActiveTab)]), SettingsActiveTab.properties),
    showScalarUncertaintyMargins: types.optional(types.boolean, true),
    bomTablesLeftActiveTab: types.optional(types.enumeration([...Object.values(BomTablesActiveTab)]), BomTablesActiveTab.bomTables),
    requirementsLeftActiveTab: types.optional(
      types.enumeration([...Object.values(RequirementsActiveTab)]),
      RequirementsActiveTab.requirements
    ),
    activeBlock: types.safeReference(BlockStore),
    activeReqBlockId: types.maybe(types.string),
    activePropertyInstance: types.safeReference<IPropertyInstanceMobxType>(types.late((): IAnyModelType => PropertyInstanceStore)),
    activeAttachment: types.safeReference<IAttachmentMobxType>(types.late((): IAnyModelType => AttachmentStore)),
    showAttachmentGrid: types.optional(types.boolean, false),
    currentBlockTab: types.optional(types.enumeration([...Object.values(EBlockTab)]), EBlockTab.PROPERTIES),
    developerMode: types.optional(types.boolean, false),
    copiedEntity: types.maybe(types.frozen<{ id: string; type: EntityType }>()),
    inboxSettings: types.optional(InboxSettingsStore, {}),
    recentWorkspaces: types.array(types.frozen<IWorkspaceListItem>()),
    recentIconNames: types.map(types.frozen<string[]>()),
    recentEntities: types.array(types.frozen<IRecentEntity>()),
    showPropertyDescription: types.optional(types.boolean, true),
    isBomTableVerticalSplit: types.optional(types.boolean, false),
    isCatalogItemTableVerticalSplit: types.optional(types.boolean, false),
    dismissedCallouts: types.array(types.string),
    isFavoritesExpanded: types.optional(types.boolean, false),
    requirementPageViewMode: types.optional(types.enumeration([...Object.values(RequirementsViewMode)]), RequirementsViewMode.Table),
  })
  .volatile<IEnvironmentStoreVolatile>(() => ({
    // In future this might be a number of different viewer types
    attachmentViewer: undefined,
    attachmentSubViewer: undefined,
    detailedAttachment: undefined,
    tableViewGridApi: undefined,
    reportsTreeGridApi: undefined,
    blocksTreeGridApi: undefined,
    catalogItemTableGridApi: undefined,
    requirementsTableGridApi: undefined,
    editors: new Map<string, Editor>(),
    dependencyGraphVisible: false,
    pinnedPropertiesVisible: false,
    detachedExpressionEditorVisible: false,
    pinnedProperties: new Array<IPropertyInstance>(),
    loadingTheme: false,
    selectedBillingPlan: undefined,
  }))
  .views(self => ({
    get themeIsDark() {
      if (self.theme === Theme.System) {
        return self.systemTheme === Theme.Dark;
      }
      return self.theme === Theme.Dark;
    },
    get themeName() {
      return this.themeIsDark ? Theme.Dark : Theme.Light;
    },
    get recentPropertyIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.PropertyInstance).map(e => e.id);
    },
    get recentDataSourceIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.DataSource).map(e => e.id);
    },
    get recentDataSinkEntryIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.DataSinkEntry).map(e => e.id);
    },
    get recentBlockIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.Block).map(e => e.id);
    },
    get recentAttachmentIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.Attachment).map(e => e.id);
    },
    get recentReportIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.Report).map(e => e.id);
    },
    get recentUserIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.User).map(e => e.id);
    },
    get recentAnalysisOutputIds(): string[] {
      return self.recentEntities.filter(e => e.type === EntityType.AnalysisOutput).map(e => e.id);
    },
    getRecentIcons(source: IconSource): IIcon[] {
      return self.recentIconNames.get(source)?.map(name => ({ source, name }) as IIcon) ?? [];
    },
  }))
  .actions(self => ({
    setLoadingTheme: (loading: boolean) => {
      self.loadingTheme = loading;
    },
    toggleBomTableVerticalSplit() {
      self.isBomTableVerticalSplit = !self.isBomTableVerticalSplit;
    },
    toggleCatalogItemTableVerticalSplit() {
      self.isCatalogItemTableVerticalSplit = !self.isCatalogItemTableVerticalSplit;
    },
    toggleNavPane: () => {
      self.isNavPaneOpen = !self.isNavPaneOpen;
    },
    toggleSubNavPane: () => {
      self.isSubNavPaneOpen = !self.isSubNavPaneOpen;
    },
    addRecentEntity(entity: IRecentEntity) {
      if (entity.type === EntityType.None) {
        return;
      }
      self.recentEntities = cast(
        [
          entity, // new entity
          ...self.recentEntities.filter(item => item.id !== entity.id), // All other entities
        ].slice(0, RECENT_ENTITIES_LIMIT) // restrict the number of entities stored
      );
    },
    setSelectedBillingPlan(plan?: ISelectedPlanData) {
      self.selectedBillingPlan = plan;
    },
  }))
  .actions(self => ({
    setTheme(theme: Theme) {
      if (theme !== self.theme) {
        self.setLoadingTheme(true);
        self.theme = theme;
      }
    },
    setSystemTheme(theme: Theme.Light | Theme.Dark) {
      self.systemTheme = theme;
    },
    addRecentWorkspace(workspace: IWorkspaceListItem) {
      if (!self.recentWorkspaces.length) {
        self.recentWorkspaces = cast([workspace]);
      } else {
        const updatedArray = self.recentWorkspaces.slice().filter(w => w.id !== workspace.id);
        updatedArray.push(workspace);
        if (updatedArray.length > 6) {
          updatedArray.shift();
        }
        self.recentWorkspaces = cast(updatedArray);
      }
    },
    removeRecentWorkspace(workspaceId: string) {
      self.recentWorkspaces = cast(self.recentWorkspaces.filter(w => w.id !== workspaceId));
    },
    addRecentIcon(icon: IIcon, disableReordering?: boolean) {
      const iconList = self.recentIconNames.get(icon.source) ?? [];
      // do not re-add the same icon if we don't want reordering (moving the most recent icon to the top of the list)
      if (iconList.includes(icon.name) && disableReordering) {
        return;
      }
      const filteredArray = iconList.filter(recentIcon => recentIcon !== icon.name);
      self.recentIconNames.set(icon.source, [icon.name, ...filteredArray].slice(0, MAX_RECENT_ICONS));
    },
    setErrorString(error: string) {
      self.errorString = error;
    },
    toggleTabulatedViewDefaultShowSingle() {
      self.tabulatedViewDefaultShowSingle = !self.tabulatedViewDefaultShowSingle;
    },
    toggleTabulatedViewDefaultShowMultiplicity() {
      self.tabulatedViewDefaultShowMultiplicity = !self.tabulatedViewDefaultShowMultiplicity;
    },
    toggleTabulatedViewDefaultShowEmpty() {
      self.tabulatedViewDefaultShowEmpty = !self.tabulatedViewDefaultShowEmpty;
    },
    toggleShowProjectStatus() {
      self.tabulatedViewShowProjectStatuses = !self.tabulatedViewShowProjectStatuses;
    },
    setHoopsTreePanelState(state: HoopsTreePanelState) {
      self.hoopsTreePanelState = state;
    },
    setHoopsSidePanelState(state: HoopsSidePanelState) {
      self.hoopsSidePanelState = state;
    },
    setFeedSidePanelState(state: FeedSidePanelState) {
      self.feedSidePanelState = state;
    },
    showAllTableColumns() {
      self.tabulatedViewDefaultShowEmpty = true;
      self.tabulatedViewDefaultShowMultiplicity = true;
      self.tabulatedViewDefaultShowSingle = true;
      self.tabulatedViewShowProjectStatuses = true;
    },
    toggleLeftSidebar() {
      self.leftSidebarIsCollapsed = !self.leftSidebarIsCollapsed;
    },
    toggleRightSidebar() {
      self.rightSidebarIsCollapsed = !self.rightSidebarIsCollapsed;
    },
    toggleShowPdmThumbnail() {
      self.showPdmThumbnail = !self.showPdmThumbnail;
    },
    toggleShowSmartAnnotations() {
      self.showSmartAnnotations = !self.showSmartAnnotations;
      self.attachmentViewer?.setAnnotationsVisible(!self.attachmentViewer?.annotationsVisible);
    },
    setShowSmartAnnotations(show: boolean) {
      self.showSmartAnnotations = show;
      self.attachmentViewer?.setAnnotationsVisible(show);
    },
    setBomTableSize(size: number) {
      self.bomTableSize = size;
    },
    setCatalogItemTableSize(size: number) {
      self.catalogItemTableSize = size;
    },
    setActiveAttachment(attachment: IAttachment) {
      if (self.attachmentViewer && attachment !== self.activeAttachment) {
        destroy(self.attachmentViewer);
        self.attachmentSubViewer && destroy(self.attachmentSubViewer);
      }
      self.detailedAttachment = undefined;

      if (attachment && self.activeAttachment !== attachment) {
        rollupClient.attachments.getMetadata(attachment.id, true, attachment.workspaceId).then(res => {
          if (res.data && isAlive(attachment) && self.activeAttachment === attachment) {
            attachment.fillMetadata(res.data);
          }
        });
      }
      attachment.annotationList.populate();
      self.activeAttachment = cast(attachment);
      self.addRecentEntity({ id: attachment.id, type: EntityType.Attachment });
    },
    clearActiveAttachment() {
      if (self.attachmentViewer) {
        destroy(self.attachmentViewer);
      }
      if (self.attachmentSubViewer) {
        destroy(self.attachmentSubViewer);
      }
      self.activeAttachment = undefined;
    },
    setAttachmentViewer(viewerInstance: Communicator.WebViewer) {
      self.attachmentViewer = HoopsViewerStore.create({
        viewerInstance,
      });
    },
    setAttachmentSubViewer(viewerInstance: Communicator.WebViewer) {
      self.attachmentSubViewer = HoopsViewerStore.create({
        viewerInstance,
      });
    },
    setDetailedAttachment(attachment: IAttachment) {
      self.detailedAttachment = attachment;
    },
    clearDetailedAttachment() {
      self.detailedAttachment = undefined;
    },
    setRequirementsTableGridApi(api?: RequirementsTableGridApi) {
      self.requirementsTableGridApi = api;
    },
    setTableViewGridApi(api?: TableViewGridApi) {
      self.tableViewGridApi = api;
    },
    setCatalogItemTableGridApi(api?: CatalogItemTableGridApi) {
      self.catalogItemTableGridApi = api;
    },
    setReportsTreeGridApi(api?: ReportTreeGridApi | undefined) {
      self.reportsTreeGridApi = api;
    },
    setBlocksTreeGridApi(api?: TableViewGridApi | undefined) {
      self.blocksTreeGridApi = api;
    },
    setShowAttachmentGrid(val: boolean) {
      self.showAttachmentGrid = val;
    },
    setActiveBlockById(id?: string) {
      const block = getBlockById(id || "");
      if (block) {
        self.activeBlock = block;
        this.clearDetailedAttachment();
        this.clearActiveAttachment();
        this.clearActivePropertyInstance();
        if (!block.commentThreadList?.threads?.length) {
          block.commentThreadList.fetchThreadList({
            initialFetch: true,
            temporalDirection: TemporalDirection.Older,
            type: CommentLocationType.Root,
            parentId: block.id,
          });
        }
        self.addRecentEntity({ id: block.id, type: EntityType.Block });
      }
    },
    setActiveBlockEntityByHash(hash: string) {
      const entity = getEntityTypeByHash(hash);
      if (entity) {
        switch (entity) {
          case EEntityShortcut.Attachment:
            self.currentBlockTab = EBlockTab.ATTACHMENTS;
            break;
          default:
            self.currentBlockTab = EBlockTab.PROPERTIES;
        }
      } else {
        showToast("Can't find entity from the url hash", Intent.WARNING);
      }
    },
    setActiveTableConfigId(id: string) {
      self.activeTableViewConfigId = id;
    },
    clearActiveBlock() {
      self.activeBlock = undefined;
      this.clearActivePropertyInstance();
      self.detailedAttachment = undefined;
    },
    setActivePropertyInstance(instance: IPropertyInstance) {
      self.activePropertyInstance = instance;
      self.addRecentEntity({ id: instance.id, type: EntityType.PropertyInstance });
    },
    setActiveReqBlockId(id: string) {
      self.activeReqBlockId = id;
    },
    clearActivePropertyInstance() {
      this.hideDetachedExpressionEditorWindow();
      self.activePropertyInstance = undefined;
    },
    setActiveReport(reportId: string) {
      self.activeReportId = reportId;
      self.addRecentEntity({ id: reportId, type: EntityType.Report });
    },
    setActiveReportBlock(id: string) {
      self.activeReportBlockId = id;
    },
    clearActiveReportBlock() {
      self.activeReportBlockId = null;
    },
    setActiveDocumentBlockId(id: string) {
      self.activeDocumentBlockId = id;
    },
    clearActiveDocumentBlockId() {
      self.activeDocumentBlockId = null;
    },
    clearActiveReport() {
      self.activeReportId = null;
    },
    setActiveBomTable(bomTableId: string) {
      if (bomTableId !== self.activeBomTableId) {
        appStore.workspaceModel?.unloadBomTable(self.activeBomTableId);
        self.activeBomTableId = bomTableId;
        appStore.workspaceModel?.populateBomTable(bomTableId);
      } else {
        self.activeBomTableId = bomTableId;
      }
      self.addRecentEntity({ id: bomTableId, type: EntityType.BomTable });
    },
    clearActiveBomTable() {
      self.activeBomTableId = null;
    },
    toggleShowScalarUncertaintyMargins() {
      self.showScalarUncertaintyMargins = !self.showScalarUncertaintyMargins;
    },
    setActiveRequirementsDocument(id: string) {
      self.activeRequirementsDocumentId = id;
      self.addRecentEntity({ id: id, type: EntityType.RequirementsDocument });
    },
    clearActiveRequirementsDocument() {
      self.activeRequirementsDocumentId = null;
    },
    setActiveAnalysis(id: string) {
      self.activeAnalysisId = id;
    },
    clearActiveAnalysis() {
      self.activeAnalysisId = null;
    },
    toggleEngineeringView() {
      self.settingShowEngineeringView = !self.settingShowEngineeringView;
    },
    setAcknowledgedFormulaProperty() {
      self.acknowledgedFormulaProperty = true;
    },
    setAcknowledgeCodeCard() {
      self.acknowledgeCodeCard = true;
    },
    setModelingLeftActiveTab(tab: ModelingActiveTab) {
      self.modelingLeftActiveTab = tab;
      this.clearActiveAttachment();
      if (tab !== ModelingActiveTab.model) {
        this.clearActiveBlock();
      }
    },
    setSettingsActiveTab(tab: SettingsActiveTab) {
      self.settingsActiveTab = tab;
    },
    setRequirementsLeftActiveTab(tab: RequirementsActiveTab) {
      self.requirementsLeftActiveTab = tab;
    },
    setBomTablesLeftActiveTab(tab: BomTablesActiveTab) {
      self.bomTablesLeftActiveTab = tab;
    },
    toggleDebugDrawerIsOpen() {
      self.debugDrawerIsOpen = !self.debugDrawerIsOpen;
    },
    setDebugDrawerIsOpen(isOpen: boolean) {
      self.debugDrawerIsOpen = isOpen;
    },
    setDrawerHeight(height: number) {
      // Enforce a minimum height
      // These are stored as percentages (hence the decimal)
      if (height < 0.3) {
        self.drawerHeight = 0.3;
      } else {
        self.drawerHeight = height;
      }
    },
    setDrawerActiveTab(tab: DrawerActiveTab) {
      self.drawerActiveTab = tab;
    },
    setCurrentBlockTab(tab: EBlockTab) {
      self.currentBlockTab = tab;
      if (tab !== EBlockTab.PROPERTIES) {
        this.clearActivePropertyInstance();
      }
    },
    pinPropertyInstance(instance: IPropertyInstance) {
      if (!self.pinnedProperties?.includes(instance)) {
        self.pinnedProperties = [...self.pinnedProperties.filter(p => isAlive(p)), instance];
      }
      // Automatically show window after pinning properties
      self.pinnedPropertiesVisible = true;
    },
    unpinPropertyInstance(instance: IPropertyInstance) {
      self.pinnedProperties = self.pinnedProperties?.filter(p => isAlive(p) && p !== instance) ?? [];
    },
    clearPinnedProperties() {
      self.pinnedProperties = new Array<IPropertyInstance>();
    },
    hidePinnedPropertiesWindow() {
      self.pinnedPropertiesVisible = false;
      // Remove all pinned properties after closing dialog. In future we will simply dock the dialog
      this.clearPinnedProperties();
    },
    showDependencyGraphWindow() {
      self.dependencyGraphVisible = true;
    },
    hideDependencyGraphWindow() {
      self.dependencyGraphVisible = false;
    },
    showDetachedExpressionEditorWindow(propertyInstance: IPropertyInstance) {
      this.setActivePropertyInstance(propertyInstance);
      self.detachedExpressionEditorVisible = true;
    },
    hideDetachedExpressionEditorWindow() {
      self.detachedExpressionEditorVisible = false;
    },
    showPropertyDetailsViewer(propertyInstance: IPropertyInstance) {
      this.setActivePropertyInstance(propertyInstance);
      self.sidePanel.setBlockSidePanel(BlockSidePanel.PropertyDetails);
    },
    showReqDetailsPanel(reqBlockId: string) {
      this.setActiveReqBlockId(reqBlockId);
      self.sidePanel.setReqSidePanel(ReqSidePanel.RequirementDetails);
    },
    toggleDeveloperMode() {
      self.developerMode = !self.developerMode;

      // When we are turning developer mode off,
      // we need to turn off all feature flags too.
      if (!self.developerMode) {
        appStore.env.featureFlags.clearFlags();
      }
    },
    setCopiedEntity(entity: IBlock | IPropertyInstance | IStatusInstance | undefined | null) {
      if (!entity) {
        self.copiedEntity = undefined;
        return;
      }
      // TODO: additional types
      self.copiedEntity = { id: entity.id, type: EntityType.Block };
      copyToClipboard(self.copiedEntity);
    },
    clearCopiedEntity() {
      self.copiedEntity = undefined;
    },
    togglePropertyDescription() {
      self.showPropertyDescription = !self.showPropertyDescription;
    },
    dismissCallout(calloutKey: string) {
      if (!self.dismissedCallouts.includes(calloutKey)) {
        self.dismissedCallouts.push(calloutKey);
      }
    },
    removeAllDismissedCallouts() {
      self.dismissedCallouts = cast([]);
    },
    toggleFavoritesExpanded() {
      self.isFavoritesExpanded = !self.isFavoritesExpanded;
    },
    setRequirementPageViewMode(mode: RequirementsViewMode) {
      self.requirementPageViewMode = mode;
    },
  }))
  .actions(self => ({
    toggleTheme() {
      if (self.themeIsDark) {
        self.setTheme(Theme.Light);
      } else {
        self.setTheme(Theme.Dark);
      }
    },
  }))
  .views(self => ({
    get isDefaultTableViewConfigTab() {
      return self.activeTableViewConfigId === defaultTableViewConfigTabId;
    },
    get previewType(): FilePreviewType {
      return getPreviewType(self.activeAttachment);
    },
  }))
  .views(self => ({
    get isHoopsPreviewMode() {
      return self.previewType === FilePreviewType.HoopsViewer;
    },
    get isBoardPreviewMode() {
      return self.previewType === FilePreviewType.BoardViewer;
    },
    get recentlyViewedWorkspaces(): IWorkspaceListItem[] {
      return self.recentWorkspaces
        .slice()
        .reverse()
        .filter(w => w.id !== appStore.workspaceModel?.id && appStore.orgModel.isValidWorkspaceId(w.id));
    },
    get copiedEntityType() {
      return self.copiedEntity?.type ?? EntityType.None;
    },
    canPasteOnBlock(block: IBlock) {
      if (!block || !self.copiedEntity || this.copiedEntityType !== EntityType.Block) {
        return false;
      }
      // Check if argument is a descendant of the copied block
      const argPath = block.pathIds;
      return !argPath.includes(self.copiedEntity.id);
    },
    hasDismissedCallout(calloutKey: string) {
      return self.dismissedCallouts.includes(calloutKey);
    },
    get isSinglePaneMode() {
      return this.isHoopsPreviewMode || this.isBoardPreviewMode;
    },
  }));

export interface IEnvironment extends Instance<typeof EnvironmentStore> {}
