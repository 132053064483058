import { useEffect, useState } from "react";
import { Icon, Intent, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import PdmVersionEditor from "@components/PdmVersionEditor";
import { Tag } from "@components/Tag";
import appStore from "@store/AppStore";
import { ICatalogItemVersion } from "@store/CatalogItem/CatalogItemVersionStore";

import styles from "./NewCatalogItemVersionDialog.module.scss";

enum SaveType {
  Draft = "draft",
  AddNew = "add-new",
  Update = "update",
}

const NewCatalogItemVersionDialog = () => {
  const showModal = !!appStore.ui.pdmNewVersionDialog;
  const catalogItem = appStore.orgModel.catalogItems.getCatalogItem(appStore.ui.pdmNewVersionDialog);
  const [material, setMaterial] = useState("");
  const [revisionCode, setRevisionCode] = useState<string>();
  const [cost, setCost] = useState("0");
  const [costCurrency, setCostCurrency] = useState("$");
  const [weight, setWeight] = useState("0");
  const [file, setFile] = useState<File | null>(null);
  const [weightUnit, setWeightUnit] = useState("kg");
  const [comment, setComment] = useState("");
  const [revisionName, setRevisionName] = useState("");
  const [addRevision, setAddRevision] = useState(false);
  const [version, setVersion] = useState<ICatalogItemVersion>();
  const [isSaving, setIsSaving] = useState(false);
  const [saveType, setSaveType] = useState<SaveType>(SaveType.AddNew);

  useEffect(() => {
    setMaterial(version?.material || "");
    setCost(version?.cost ? version.cost.toString() : "0");
    setCostCurrency(version?.costCurrency || "$");
    setWeight(version?.weight ? version.weight.toString() : "0");
    setWeightUnit(version?.weightUnit || "kg");
    setComment(version?.comment || "");
    setRevisionName(version?.revisionName || "");
    setRevisionCode(version?.revisionCode || "");
  }, [version, showModal]);

  if (!showModal || !catalogItem) {
    return null;
  }

  const handleSave = async (updateCurrent = false, isDraft = false) => {
    setIsSaving(true);
    let attachmentId: string | undefined;

    if (file) {
      attachmentId = await appStore.orgModel.attachments.add({ label: file.name }, file);
    }

    const versionData = {
      material,
      cost: Number(cost),
      costCurrency,
      weight: Number(weight),
      weightUnit,
      revisionCode,
      comment,
      isDraft,
      attachmentId,
      revisionName: addRevision ? revisionName : undefined,
    };

    if (version && (version?.isDraft || updateCurrent)) {
      await appStore.orgModel.catalogItems.updateCatalogItemVersion(version.id, versionData);
    } else {
      const id = uuidv4();
      await appStore.orgModel.catalogItems.createCatalogItemVersion({
        id,
        catalogItemId: catalogItem.id,
        ...versionData,
      });
    }

    setIsSaving(false);
    setVersion(undefined);
    appStore.ui.hidePdmNewVersionDialog();
  };

  return (
    <DialogLegacy
      className={styles.newCatalogItemVersionDialog}
      isOpen
      title={`New version of ${catalogItem.name}`}
      onClose={appStore.ui.hidePdmNewVersionDialog}
      lazy
      canEscapeKeyClose
    >
      <div className={styles.newCatalogItemVersionDialogContent}>
        <div className={styles.newCatalogItemVersionDialogSidebar}>
          <div
            onClick={() => setVersion(undefined)}
            className={classNames(styles.newCatalogItemVersionDialogItem, {
              [styles.newCatalogItemVersionDialogItemActive]: !version,
            })}
          >
            <Icon icon="cube" />
            <div>Add new version</div>
          </div>
          {catalogItem.sortedVersions.map((v: ICatalogItemVersion) => (
            <div
              key={v.id}
              className={classNames(styles.newCatalogItemVersionDialogItem, {
                [styles.newCatalogItemVersionDialogItemActive]: v.id === version?.id,
              })}
              onClick={() => setVersion(v)}
            >
              <Icon icon="cube" />
              <div>
                <div className={styles.newCatalogItemVersionDialogItemTitle}>
                  {v.index} {v.isDraft && <div className={styles.newCatalogItemVersionDialogItemComment}>(Draft)</div>}
                  {v.revisionCode && (
                    <Tooltip content={v.revisionName || undefined}>
                      <Tag minimal>Rev {v.revisionCode}</Tag>
                    </Tooltip>
                  )}
                </div>
                <div className={styles.newCatalogItemVersionDialogItemComment}>{v.comment}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.newCatalogItemVersionDialogBodyWrap}>
          <PdmVersionEditor
            onSettingsClick={appStore.ui.hidePdmNewVersionDialog}
            addRevision={addRevision}
            setAddRevision={setAddRevision}
            setRevisionCode={setRevisionCode}
            className={styles.newCatalogItemVersionDialogBody}
            revisionName={revisionName}
            setRevisionName={setRevisionName}
            comment={comment}
            setComment={setComment}
            material={material}
            setMaterial={setMaterial}
            weight={weight}
            setWeight={setWeight}
            weightUnit={weightUnit}
            setWeightUnit={setWeightUnit}
            version={version}
            file={file}
            setFile={setFile}
            cost={cost}
            setCost={setCost}
            costCurrency={costCurrency}
            catalogItem={catalogItem}
            setCostCurrency={setCostCurrency}
          />
          <div className={styles.newCatalogItemVersionDialogFooter}>
            <Button disabled={isSaving} minimal onClick={appStore.ui.hidePdmNewVersionDialog} e2eIdentifiers="btn-cancel-new-version" large>
              Cancel
            </Button>
            <Button
              disabled={isSaving}
              loading={isSaving && saveType === SaveType.Draft}
              outlined
              onClick={() => {
                setSaveType(SaveType.Draft);
                handleSave(false, true);
              }}
              e2eIdentifiers="btn-confirm-draft-version"
              large
            >
              Save as draft
            </Button>
            <Button
              disabled={isSaving}
              loading={isSaving && saveType === SaveType.AddNew}
              intent={Intent.PRIMARY}
              onClick={() => {
                setSaveType(SaveType.AddNew);
                handleSave(false);
              }}
              e2eIdentifiers="btn-confirm-new-version"
              large
            >
              {version ? "Save as new" : "Create"}
            </Button>
            {version && (
              <Button
                disabled={isSaving}
                loading={isSaving && saveType === SaveType.Update}
                intent={Intent.PRIMARY}
                onClick={() => {
                  setSaveType(SaveType.Update);
                  handleSave(true);
                }}
                e2eIdentifiers="btn-confirm-update-version"
                large
              >
                Update
              </Button>
            )}
          </div>
        </div>
      </div>
    </DialogLegacy>
  );
};

export default observer(NewCatalogItemVersionDialog);
