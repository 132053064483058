import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { EntityModelBase } from "@rollup-api/shared/entityModelBase";
import { IAnalysisOutputSnapshotIn } from "@store/Analysis/AnalysisOutputStore";
import { convertTimestamp } from "@utilities";

export class AnalysisOutput extends EntityModelBase {
  public readonly analysisId!: string;
  public readonly label!: string;
  public readonly value?: string;
  public readonly unit?: string;
  public readonly orderIndex?: number;
}

export function toAnalysisOutputSnapshot(analysisOutput: AnalysisOutput): IAnalysisOutputSnapshotIn {
  const output = omitBy(analysisOutput, isNull) as AnalysisOutput;
  return {
    ...output,
    analysis: analysisOutput.analysisId,
    createdAt: convertTimestamp(output.createdAt),
    updatedAt: convertTimestamp(output.updatedAt),
  };
}
