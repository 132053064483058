import { delay } from "@utilities/Async";

export const isValidImageUrl = async (url: string): Promise<boolean> => {
  const img = new Image();
  img.src = url;

  return new Promise(resolve => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

export const openImage = async (dataUrl: string, filename = "Image") => {
  const newWindow = window.open("about:blank");
  const image = new Image();
  image.src = dataUrl;
  await delay(0);
  if (!newWindow) {
    return;
  }

  newWindow.document.write(image.outerHTML);
  newWindow.document.title = filename;
};

export const copyImageToClipboard = async (blob: Blob) => {
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        "image/png": blob,
      }),
    ]);
  } catch (error) {
    console.error(error);
  }
};

export const downloadImage = (url: string) => {
  const save = document.createElement("a");
  save.href = url;
  save.target = "_blank";
  save.click();
};

export const getPngBlob = (imageUrl: string): Promise<Blob> => {
  const image = new Image();
  image.crossOrigin = "anonymous"; // canvas could not read image data without this
  image.src = imageUrl;

  return new Promise(resolve => {
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 500;
      canvas.height = 500;
      canvas.getContext("2d")?.drawImage(image, 0, 0);
      const url = canvas.toDataURL("image/png");
      fetch(url).then(res => resolve(res.blob()));
    };
  });
};
